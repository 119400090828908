.container{
	display: flex;
	justify-content: center;
	/* align-items: center; */
	background-color: #E4E4E4;
	min-height: 100vh;
}

.card{
	width: 80%;
	display: block;
	background-color: #FFFFFF;
	padding: 30px;
	box-shadow: 2px 2px 10px #8a8a8a;
	border-radius: 10px;
	margin: 30px auto;
}

.row {
    padding: 10px;
    color: #555;
    cursor: pointer;
	border: 1px solid #c5c5c5;
    border-radius: 5px;
    margin: 15px 0;
}

.row:hover {
    background-color: #ffffff;
}

.filteredDataContainer{
	padding: 20px 30px;
}
