.popContainer{
    position: relative;
}

.popup{
    position: absolute;
    top: 100%;
    width: 100%!important;
    left: 0;
    
}

.popContent{
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #b7b7b7;
    background-color: #F7F7F8;
    /* margin-top: 5px; */
}

.searchInput{
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid #b7b7b7;
    padding: 10px 20px;
    cursor: pointer;
    width: 100%;
    margin: 0;
    box-sizing: border-box; 
}

.inputRow{
    display: grid;
    grid-template-columns: 0.8fr 0.1fr 0.1fr;
    grid-gap: 10px;
}

.button{
    border-radius: 10px;
    border: 1px solid #b7b7b7;
    cursor: pointer;
}

.searchBtn{
    background-color: rgb(1, 138, 1);
    color: #ffffff;
}

.clearBtn{
    color: red;
}

.popTitle {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
}

.popList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.popItem {
    padding: 8px;
    border-bottom: 1px solid #eee;
    color: #444;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.popItem:hover {
    background-color: #f2f2f2;
}